import React from 'react';

import Layout, {
    bgImageDefault,
    Col2,
    PageHeaderA,
    PageSection,
} from '../components/layout';
import { PageText } from '../components/typography';
import { BoxLight } from '../components/box';
import { StaticImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import Seo from '../components/seo';
import { SecondaryButton } from '../components/buttons';

function Platform({ data, location }) {
    const { t } = useTranslation();
    const texts = [
        {
            title: t('rating_title', 'Security Rating'),
            desc: t(
                'rating_desc',
                'Security messbar und vergleichbar - die perfekte Basis für schnellere und fundierte Entscheidungen.'
            ),
            url: '/security-rating/',
        },
        {
            title: t('vulnerability_title', 'Vulnerability Management'),
            desc: t(
                'vulnerability_desc',
                'Überblick bewahren und Schwachstellen effizient behandeln.'
            ),
            url: '/vulnerability-management/',
        },
        {
            title: t('srm_title', 'Security Controls Management'),
            desc: t('srm_desc', 'Entwicklung und Betrieb proaktiv absichern.'),
            url: '/security-controls-management/',
        },
        {
            title: t('scan_title', 'Security Scan Services'),
            desc: t(
                'scan_desc',
                'Vorhandene Schwachstellen erkennen, noch bevor ein Angreifer es tut.'
            ),
            url: '/security-scan-services/',
        },
        {
            title: t('thirdparty_title', 'Third Party Management'),
            desc: t(
                'thirdparty_desc',
                'Risiken in der Supply-Chain identifizieren und mitigieren.'
            ),
            url: '/third-party-management/',
        },
        {
            title: t('risk_management_title', 'Risk Management'),
            desc: t(
                'risk_management_desc',
                'Frühzeitige Identifikation, Bewertung und Priorisierung von potenziellen Risiken, um Ressourcen effizient zu nutzen und die Unternehmenssicherheit zu stärken.'
            ),
            url: '/risk-management/',
        },
        {
            title: t('compliance_management_title', 'Compliance Management'),
            desc: t(
                'compliance_management_desc',
                'Mit den wachsenden Compliance-Anforderungen Schritt halten. '
            ),
            url: '/compliance-management/',
        },
    ];
    return (
        <Layout
            location={location}
            bgImage={{ file: bgImageDefault.platform.file, opacity: 0.3 }}
            headerContent={
                <PageHeaderA
                    title={t('title', 'Plattform')}
                    text={t(
                        'subtitle',
                        'Sicherer mit der bewährten condignum Plattform.'
                    )}
                    image={
                        <StaticImage
                            src="../images/headers/Plattformloop.png"
                            alt="Plattform"
                            className="block max-w-sm"
                            objectFit="cover"
                            placeholder="none"
                            height={384}
                            quality={100}
                            formats={['auto', 'png']}
                        />
                    }
                />
            }
        >
            <Seo title={t('title', 'Platform')} />
            <PageSection size="big" isDark className="pb-8">
                <PageText
                    className="max-w-4xl bg-gradient-to-r from-primary to-light-grey text-clean-white py-4 px-4 flex items-center justify-between"
                    type="smallCaps"
                >
                    <Trans i18nKey="heading">Die Condignum Plattform</Trans>
                </PageText>
                <Col2>
                    {texts.map(t => (
                        <BoxLight
                            className="p-4 pb-6 flex flex-col justify-between items-start"
                            key={t.url}
                        >
                            <div className="mb-0">
                                <PageText type="highlighted" className="mb-0">
                                    {t.title}
                                </PageText>
                                <PageText>{t.desc}</PageText>
                            </div>

                            <SecondaryButton to={t.url} className="mt-2 px-6">
                                <Trans i18nKey="more">Mehr Erfahren</Trans>
                            </SecondaryButton>
                        </BoxLight>
                    ))}
                </Col2>
            </PageSection>
        </Layout>
    );
}

export const query = graphql`
    query($language: String!) {
        locales: allLocale(
            filter: { ns: { in: ["platform"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

export default Platform;
